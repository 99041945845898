.resizer {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 3px;
    cursor: col-resize;
    user-select: none;
    touch-action: none;
}

.resizer.isResizing {
    background: blue;
    opacity: 1;
}
