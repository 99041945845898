:root {
  --tooltip-text-color: white;
  --tooltip-background-color: black;
  --tooltip-margin: 30px;
  --tooltip-arrow-size: 6px;
}

.tooltip-wrapper {
  display: inline-block;
  position: relative;
}

.tooltip-orientation {
  position: absolute;
  border-radius: 5px;
  left: 50%;
  transform: translateX(-50%);
  padding: 8px;
  color: var(--tooltip-text-color);
  background: var(--tooltip-background-color);
  font-size: 13px;
  line-height: 1.5;
  z-index: 100;
  white-space: nowrap;
}

.tooltip-orientation::before {
  content: " ";
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: var(--tooltip-arrow-size);
  margin-left: calc(var(--tooltip-arrow-size) * -1);
}

.tooltip-orientation.top {
  top: calc(var(--tooltip-margin) * -1.5);
}

.tooltip-orientation.top::before {
  top: 100%;
  border-top-color: var(--tooltip-background-color);
}

.tooltip-orientation.right {
  left: calc(100% + var(--tooltip-margin));
  top: 50%;
  transform: translateX(0) translateY(-50%);
}

.tooltip-orientation.right::before {
  left: calc(var(--tooltip-arrow-size) * -1);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-right-color: var(--tooltip-background-color);
}

.tooltip-orientation.bottom {
  bottom: calc(var(--tooltip-margin) * -1.5);
}

.tooltip-orientation.bottom::before {
  bottom: 100%;
  border-bottom-color: var(--tooltip-background-color);
}

.tooltip-orientation.left {
  left: auto;
  right: calc(100% + var(--tooltip-margin));
  top: 50%;
  transform: translateX(0) translateY(-50%);
}

.tooltip-orientation.left::before {
  left: auto;
  right: calc(var(--tooltip-arrow-size) * -2);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-left-color: var(--tooltip-background-color);
}
