@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    box-sizing: border-box !important;
}

:root {
    --font-family: 'Founders Grotesk';
    --primary: #ff7558;
    --accent: #2e7b8b;
    --accent-dark: #124c58;
    --accent-2: #10123a;
}

body {
    margin: 0;
    font-family: var(--font-family), -apple-system, BlinkMacSystemFont,
        'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    color: var(--accent-2);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    min-width: 20rem;
    min-height: 30rem;
    background: #f6f6f6;
    position: relative;
    overflow: hidden;
}

a {
    color: var(--accent);
    font-weight: 600;
}

.btn-sm {
    @apply h-7 text-[13px];
}

.btn-alt {
    @apply bg-lighter-teal text-[#3095AA] active:!bg-light-teal;
}

.btn-open {
    @apply bg-white border border-[#3095AA] text-[#3095AA] active:!bg-lighter-teal;
}

.btn-white {
    @apply bg-white text-accent active:!bg-lighter-teal;
}

.btn-red {
    @apply bg-red text-white;
}

.btn-light-red {
    @apply bg-[#ffeaea] text-[#ef5658];
}

@layer base {
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input.picker[type='date'] {
        position: relative;
    }

    input.picker[type='date']::-webkit-calendar-picker-indicator {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        padding: 0;
        color: transparent;
        background: transparent;
    }
}
